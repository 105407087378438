import React from "react"
import styled from "styled-components"
import { Link } from "gatsby";

import { Article } from "../types"
import { useAttributes, useLatestArticles } from "../hooks"
import { getArticleUrl, breakpoint, font } from "../util"

type LatestTwoArticlesProps = {
  skipId?: string;
}

export const LatestTwoArticles: React.FC<LatestTwoArticlesProps> = props => {
  const { skipId } = props;
  const { data, loading } = useLatestArticles(3);
  const articles = (data?.getContents?.items || [])
    .filter(article => article.id !== skipId)
    .slice(0, 2);
  return <StyledContainer data-testid="latest-two-articles">
    <VisualBlock>
      <div>
        <span className="articles">Articles</span>
        <span className="insights">& Insights</span>
      </div>
    </VisualBlock>
    <div>
      {articles.map(article => <ArticleCard article={article} key={article.id} />)}
    </div>
  </StyledContainer>
}

type ArticleCardProps = {
  article: Partial<Article>;
}
const ArticleCard: React.FC<ArticleCardProps> = props => {
  const { article } = props;
  const { hero_image_description } = useAttributes(article.attributes) as any;
  return <StyledArticleCard to={getArticleUrl(article.slug)}>
    <ImageContainer>
      <img src={article.thumbnail} alt={hero_image_description} />
    </ImageContainer>
    <LabelContainer>
      <span className="category">{article.category?.name}</span>
      <h3 className="title">{article.title}</h3>
    </LabelContainer>
  </StyledArticleCard>
}

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0;
  ${breakpoint.mobile`  
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 24px;
  `}
`

const VisualBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 232px;
  width: 100%;
  background: #F3F4EC;

  font-family: 'Austin Web';
  font-style: normal;
  font-weight: normal;
  font-size: 54px;
  line-height: 72px;
  color: ${({ theme }) => theme.templateTextColor};
  > div {
    display: flex;
    flex-direction: column;
  }
`

const StyledArticleCard = styled(Link)`
  display: flex;
  text-decoration: none;
  margin: 24px 0;
  &:hover {
    text-decoration: none;
  }
  ${breakpoint.mobile`
    margin: 0;
    &:first-child {
      margin-bottom: 24px;
    }
  `}
`

const ImageContainer = styled.div`
  min-height: 104px;
  max-height: 104px;
  min-width: 104px;
  max-width: 104px;
  img {
    height: 100%;
    width: auto;
    object-fit: cover;
  }
`

const LabelContainer = styled.div`
  margin: 0 24px;
  .category {
    ${font.c8}
    text-transform: uppercase;
    color: #666666;
    vertical-align: top;
    line-height: 1;
  }
  .title {
    ${font.m4}
    color: #232628;
  }
`