import React from 'react'
import { graphql } from 'gatsby'

import ArticlePage from '../components/articlePage'
import { Article } from '../types'

type ArticleTemplateProps = {
  data: {
    Content: {
      getContents: {
        items: Article[]
      }
    }
  }
}

const ArticleTemplate: React.FC<ArticleTemplateProps> = props => {
  const article = props.data.Content.getContents.items[0];
  
  return <ArticlePage article={article} />
}

export const query = graphql`
  query($slug: String!) {
    Content {
      getContents(limit: 1, filter: {type: { eq: "article" }, slug: { eq: $slug } }) {
        items {
          id
          slug
          title
          type
          publishDate
          subtitle
          tags
          thumbnail
          category {
            path
            filter
          }
          attributes {
            name
            value
          }
          sections {
            bodies {
              data
              type
              attributes {
                name
                value
              }
            }
            type
            attributes {
              name
              value
            }
          }
          authors {
            firstName
            id
            lastName
            bio
            slug
          }
        }
      }
    }
  }
`;

export default ArticleTemplate
