import React, { useEffect } from 'react'
import styled from 'styled-components'
import * as dayjs from 'dayjs'

import { font, getArticleUrl, getAuthorUrl, getBrowserTitle, getCurrentUrl, mapMetaTags } from '../util'
import { Article } from '../types'
import { DynamicSection } from './dynamicSection'
import SEO from './seo';
import { useAttributes, useViewDetector } from '../hooks';
import { LatestTwoArticles } from './latestTwoArticles';
import { NavigationBreadcrumbs, ShareLinks, HeroImage, Container, Link } from './ui'
import { getWordSeparator } from '../util/formatUtil'
import { getUrlQuery } from '../util/urls';

type FullArticleProps = {
  article: Article;
  pendingMessage?: string | JSX.Element;
  isPrimaryArticle?: boolean;
}

const FullArticle: React.FC<FullArticleProps> = props => {
  const { article, pendingMessage = 'Loading...', isPrimaryArticle } = props
  const { hero_image, hero_image_description, hero_image_caption } = useAttributes(article?.attributes) as any;
  const [inView, ref] = useViewDetector({ stayOn: false, turnOffAfterElement: true })
  const authorLinks = (article?.authors || [])
    .map((author, index, { length }) => <React.Fragment key={author.id}>
      <TopAuthorLink to={getAuthorUrl(author.slug)}>
        {author.firstName} {author.lastName}
      </TopAuthorLink>
      <span>{getWordSeparator(index, length)}</span>
    </React.Fragment>);

  useEffect(() => {
    if (!inView || typeof window === "undefined") return;
    window.history.replaceState(null, null, getArticleUrl(article.slug) + getUrlQuery());
  }, [inView])

  const shareLinksProps = {
    content: article,
    template: "Read this article I found on Firstly",
    emailTemplate: "Check out this great article I read on Firstly.com!",
    emailSubject: "Read this great article I found on Firstly.com",
    children: "Share this article",
  }

  return article ? (
    <article ref={ref}>
      {isPrimaryArticle || inView ? <SEO
        title={getBrowserTitle(article)}
        meta={mapMetaTags(article)}
        canonical={getCurrentUrl(article)}
      /> : null}
      <Container>
        <NavigationBreadcrumbs
          links={['home', 'articles']}
          customLinks={[{ link: `/articles/${article?.category?.path}/`, text: article?.category?.filter }]}
        />
        <h1 className="title">{article.title}</h1>
        <h2 className="subtitle">{article.subtitle}</h2>
        <div className="publish-date">
          <span>
            by {authorLinks} | {dayjs(new Date(article.publishDate)).format('MMMM D, YYYY')}
          </span>
          <ShareLinks {...shareLinksProps} />
        </div>
      </Container>
      <HeroImage src={hero_image} alt={hero_image_description} caption={hero_image_caption} />
      <ContentWrapper>
        {article.sections.map((section, index) => <DynamicSection section={section} key={index} />)}
      </ContentWrapper>
      <ShareLinksContainer>
        <ShareLinks {...shareLinksProps} />
      </ShareLinksContainer>
      <Container>
        <AboutTheAuthor>
          <h2>About the Author</h2>
          {(article?.authors || []).map(author => (<div key={author.id}>
            <div dangerouslySetInnerHTML={{ __html: author.bio }} />
            <AuthorLink to={getAuthorUrl(author.slug)}>View more by this author →</AuthorLink>
          </div>))}
        </AboutTheAuthor>
      </Container>
      <Container>
        <LatestTwoArticles skipId={article?.id} />
      </Container>
    </article>
  ) : (
    <Container>
      {pendingMessage}
    </Container>
  )
}

const ContentWrapper = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
  .dynamic-section {
    padding: 0 !important;
    .body-group:first-child {
      padding-top: 0;
    }
    &:last-child {
      .body-group:last-child {
        padding-bottom: 6px;
      }
    }
  }
`

const ShareLinksContainer = styled(Container)`
  margin-bottom: 36px;
`

const AboutTheAuthor = styled.div`
  padding-top: 24px;
  border: 1px dashed #D9D9D9;
  border-left: none;
  border-right: none;
  > h2 {
    ${font.c3}
    color: ${({ theme }) => theme.templateTextColor};
    margin-bottom: 8px;
  }
  > div {
    margin-bottom: 24px;
    > div {
      display: inline;
    }
    p {
      margin-bottom: 0;
      display: inline;
    }
  }
`

const AuthorLink = styled(Link)`
  display: block;
  margin-top: 8px;
`

const TopAuthorLink = styled(Link)`
  ${font.c7}
`

export default FullArticle
