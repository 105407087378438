import React from 'react';
import styled from "styled-components";

import { breakpoint, font } from "../util";

type TemplateWrapperProps = {
  className?: string;
}
export const TemplateWrapper: React.FC<TemplateWrapperProps> = props => {
  const { className, children } = props;
  return <StyledTemplateWrapper className={className}>
    {children}
  </StyledTemplateWrapper>
}

export const StyledTemplateWrapper = styled.div`
  text-align: left;
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${props => props.theme.templateTextColor};
  }
  h1 {${font.c1}}
  h2 {${font.c2}}
  h3 {${font.c3}}
  h4, h5, h6 {${font.c4}}
  p {${font.c5}}
  .publish-date {
    ${font.c7}
    color: #666666;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 32px;
  }
  .image-caption: {
    ${font.c7}
  }
  margin-top: 24px;
  ${breakpoint.tablet`
    margin-top: 32px;
  `}
`