import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { Article } from '../types';

const getNextArticleQuery = gql`
  query getNextArticle($prevDate: AWSDateTime) {
    getContents(limit: 1, filter: {type: { eq: "article" }, publishDate: { lt: $prevDate } }) {
      items {
        id
        slug
        title
        type
        publishDate
        subtitle
        tags
        thumbnail
        category {
          path
          filter
        }
        attributes {
          name
          value
        }
        sections {
          bodies {
            data
            type
            attributes {
              name
              value
            }
          }
          type
          attributes {
            name
            value
          }
        }
        authors {
          firstName
          id
          lastName
          bio
          slug
        }
      }
    }
  }
`

type ArticlePreviewResult = {
  getContents: {
    items: Article[];
  }
}

export const useNextArticles = (initialArticle: Article, fetchTrigger: any) => {
  let [{ articles, loading, error, finished }, setData] = useState({
    articles: [initialArticle],
    loading: false,
    error: null,
    finished: false,
  });
  const { fetchMore = () => Promise.reject() } = useQuery<ArticlePreviewResult>(getNextArticleQuery, { fetchPolicy: 'standby' });

  useEffect(() => {
    if (!fetchTrigger || finished) return;
    fetchMore({
      variables: { prevDate: articles[articles.length - 1].publishDate },
    }).then(({ data, loading, error }) => {
      setData({
        articles: [...articles, ...data.getContents.items],
        loading,
        error,
        finished: data.getContents.items.length === 0,
      });
    }).catch(console.error.bind(console));
    return () => { setData = () => {} } // cleanup so as not to update an unmounted component
  }, [fetchTrigger])

  return { articles, loading, error }
}