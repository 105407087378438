import React from 'react'
import { Article } from '../types'
import { Layout } from './layout'
import FullArticle from './fullArticle'
import { TemplateWrapper } from './templateWrapper';
import { useNextArticles } from '../hooks/useNextArticles';
import { useViewDetector } from '../hooks/useViewDetector';

type ArticlePageProps = {
  article: Article,
}

const ArticlePage: React.FC<ArticlePageProps> = (props) => {
  const { article } = props;
  const [viewStatus, ref] = useViewDetector({ stayOn: false })
  const { articles, loading } = useNextArticles(article, viewStatus);
  return (
    <Layout>
      <TemplateWrapper>
        {articles.map((a, i) => <FullArticle article={a} key={a?.slug} isPrimaryArticle={i === 0}/>)}
        <div ref={ref as any} key="detector">{loading ? 'Loading...' : null}</div>
      </TemplateWrapper>
    </Layout>
  )
}

export default ArticlePage
